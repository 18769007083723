import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from '@inflight/ui-ng';
import { AuthGuard } from './auth/auth-guard.service';
import { LayoutsModule } from './layouts/layouts.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/partners/view',
    pathMatch: 'full'
  },
  {
    path: 'partners',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./partner/partner.module').then(m => m.PartnerModule)
  },
  {
    path: '',
    loadChildren: () =>
    import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: '**',
    component: ErrorComponent,
    data: {
      errorType: 'not-found'
    }
  }
];

@NgModule({
  imports: [LayoutsModule, RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
