import { Injectable } from '@angular/core';
import { IInputField, IOption, ISelectField } from '@inflight/core-models';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateUIFieldsService {

  constructor(private translate: TranslateService) { }

  /**
   * create IInputField elements based on key names of object being passed and values of the keys
   * @param allFieldNames object containing all the key names for the different fields and all initial values
   * @param nodeType default nodetype for the element, by default set to be input
   * @param config optional parameter, used to overwrite required value and type value of the input
   * @returns object containing all of the IInputFields
   */
  public async createFieldsFromName(allFieldNames, nodeType = 'input', config?): Promise<any> {
    const results = {} as any;
    const basicInput = {
      disabled: false,
      id: '',
      name: '',
      type: '',
      value: '',
      nodeType,
      label: '',
      required: false
    } as IInputField;
    await Object.keys(allFieldNames).forEach(async fieldName => {
      const field = { ...basicInput };
      field.name = field.id = fieldName;
      const translateObservable = this.translate.get([`${fieldName}_label`]).pipe(take(1));
      const text = await lastValueFrom(translateObservable);
      field.label = text[`${fieldName}_label`];
      if (allFieldNames[fieldName]) {
        field.value = allFieldNames[fieldName];
      }
      if (config){
        if (config[`${fieldName}_required`]) {
          field.required  = config[`${fieldName}_required`];
        }
        if (config[`${fieldName}_type`]) {
          field.type = config[`${fieldName}_type`];
        }
      }
      results[fieldName] = field;
    });
    return results;
  }

  /**
   * transform IInputField generated from createFieldsFromName to an ISelectField
   * selected option is the one corresponding to value of input
   * @param field object containing required properties for the select
   * @param options object of whose key-value pair represent the different option values
   * @returns ISelectField with IOptions array
   */
  public async createSelectField(field: any, options: any) {
    const selectField = await this.createFieldsFromName(field, 'select');
    const select = selectField[Object.keys(selectField)[0]];
    select.options = [];
    await Object.keys(options).forEach(async (key, index) => {
      const translateKey = (options[key]) ? `${select.name}_option_${options[key]}`: `${select.name}_option_default`;
      const translateObservable = this.translate.get(translateKey).pipe(take(1));
      const text = await lastValueFrom(translateObservable);
      const option: IOption = {
        value: options[key].toString(),
        selected: (select.value === options[key]),
        index,
        nodeType: 'option',
        required: false,
        text,
        disabled: false
      }
      select.options.push(option);
    });
    return select;
  }
}
