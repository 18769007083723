import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CoreNgOptions, EndHttpEvent, Http, LastResponseService, StartHttpEvent } from '@inflight/core-ng';
import { lastValueFrom, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService extends Http {

  constructor(
    router: Router,
    lastService: LastResponseService,
    coreNgOption: CoreNgOptions,
    private httpClient: HttpClient,
  ) {
    super(router, lastService, coreNgOption);
  }

  public async GET(url: string, queryParams?: HttpParams, headers?: HttpHeaders, notifyStartTransition: boolean = true, notifyEndTransition: boolean = true) {
    this.httpEventsSubject.next(new StartHttpEvent(notifyStartTransition));
    let getOptions;
    if (headers) {
      getOptions = {
        headers
      };
    }
    if (queryParams) {
      const paramString = queryParams.toString();
      url = `${url}?${paramString}`;
    }
    const getObservable = this.httpClient.get(url, getOptions).pipe(take(1));
    const response = await lastValueFrom(getObservable);
    this.httpEventsSubject.next(new EndHttpEvent(notifyEndTransition));
    return response;
  }

  public async POST(url: string, body: any, headers?: HttpHeaders, notifyStartTransition: boolean = true, notifyEndTransition: boolean = true) {
    this.httpEventsSubject.next(new StartHttpEvent(notifyStartTransition));
    let postOptions;
    if (headers) {
      postOptions = {
        headers
      };
    }
    const postObservable = this.httpClient.post(url, body, postOptions).pipe(take(1));
    const response = await lastValueFrom(postObservable);
    this.httpEventsSubject.next(new EndHttpEvent(notifyEndTransition));
    return response;
  }

  public async PUT(url: string, body: any, headers?: HttpHeaders, notifyStartTransition: boolean = true, notifyEndTransition: boolean = true) {
    this.httpEventsSubject.next(new StartHttpEvent(notifyStartTransition));
    let putOptions;
    if (headers) {
      putOptions = {
        headers
      };
    }
    const putObservable = this.httpClient.put(url, body, putOptions).pipe(take(1));
    const response = await lastValueFrom(putObservable);
    this.httpEventsSubject.next(new EndHttpEvent(notifyEndTransition));
    return response;
  }

  public async DELETE(url: string, headers: HttpHeaders, body?: any, notifyStartTransition: boolean = true, notifyEndTransition: boolean = true) {
    this.httpEventsSubject.next(new StartHttpEvent(notifyStartTransition));
    let deleteOptions;
    if (body) {
      deleteOptions = {
        body,
        headers
      };
    }
    const deleteObservable = this.httpClient.delete(url, deleteOptions).pipe(take(1));
    const response = await lastValueFrom(deleteObservable);
    this.httpEventsSubject.next(new EndHttpEvent(notifyEndTransition));
    return response;
  }


}
